.add-category {
  padding: 20px;
}

.add-category h1 {
  font-size: 40px;
}

.add-category form {
  margin-top: 20px;
}

.add-category .form-group {
  margin-bottom: 10px;
}

.add-category label b {
  color: gray;
  font-weight: 600;
}

.add-category input[type='text'],
.add-category input[type='text']:focus {
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-bottom: 1px solid gray;
}

.add-category button[type='submit'] {
  width: 100%;
  margin: 30px 0;
  border: none;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
