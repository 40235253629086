.footer-main {
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: #110f2d;
  height: 300px;
  border-top: 1px solid #fff;
}

.footer-links {
  color: #fff;
  text-decoration: none;
}

.footer-links:hover {
  color: #04aa6d;
  text-decoration: none;
}

.copyright-footer {
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: #000;
}

.white-text {
  color: white;
}

@media only screen and (max-width: 767px) {
}
