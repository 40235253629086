.register-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 180px;
  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  gap: 3rem;
}

.register-title-row {
  margin: 20px;
  margin-bottom: 0;
  overflow: visible;
  white-space: nowrap;
}

.register-title {
  font-size: 3rem;
  width: 400px;
  font-weight: 800;
  color: var(--primary);
  position: relative;
  left: -50px;
  margin-left: -50px;
  margin-bottom: 20px;
}

.register-row {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}

.register-card {
  opacity: 1;
  transition-property: background-color, box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transform: scale(1);
  padding: 1.35rem 1.75rem;
  width: 550px;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--primary);
  border-radius: 10px;
  font-family: 'Montserrat';
  cursor: pointer;
  position: relative;
}

a.register-card {
  text-decoration: none;
}

.login-link {
  font-weight: 500;
  margin-top: 1rem;
  color: var(--primary);
}

.login-link a {
  color: var(--secondary);
}

.register-card:first-child {
  margin-bottom: 30px;
}

.register-card:hover,
.register-card:focus {
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  background-color: var(--primary);
  opacity: 1;
}

.register-card:hover h3,
.register-card:hover p,
.register-card:focus h3,
.register-card:focus p {
  color: white;
}

.register-card .arrow {
  transition: transform 0.3s ease;
}

.register-card:hover .arrow,
.register-card:focus .arrow {
  background: white;
  transform: translateX(10px);

  &:before,
  &:after {
    content: '';
    background: white;
  }
}

.register-card h3 {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--primary);
}

.register-card p {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.95rem;
  margin-top: 0.5rem;
  width: 500px;
}

.register-card:focus {
  outline: 2px solid gray;
  outline-offset: 5px;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.acad-register-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 500;
  padding: 30px;
  margin-top: 130px;
}

.acad-register-row {
  display: flex;
  gap: 2rem;
  margin-top: 0.25rem;
  align-items: center;
}

.acad-register-row h1 {
  min-width: 400px;
  font-weight: 800;
  color: var(--primary);
  text-align: left;
  font-size: 3rem;
  /* margin-top: 2rem; */
}

.acad-register-form {
  max-width: 600px;
}

.back-to-reg-btn {
  margin-bottom: 1rem;
}

.back-to-reg-btn button {
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--primary);
  border-color: var(--primary);
  transition: all 0.3s ease;
  text-decoration: none;
}

.back-to-reg-btn button:hover {
  border-color: var(--primary);
  background-color: var(--primary);
  color: white;
}

.acad-register-form input:not([type='checkbox']) {
  background: none;
  border-radius: 0;
  /* width: 400px; */
  border: none;
  border-bottom: 2px solid var(--primary);
}

.acad-register-form input:not([type='checkbox'])::placeholder {
  color: #999;
  margin-left: -10px;
  padding-left: 0;
}

.acad-register-form input:not([type='checkbox']):focus {
  background: none;
  outline: none !important;
  box-shadow: none;
  border-color: var(--secondary);
}

.acad-register-form .acad-register-btn {
  background-color: var(--primary);
  border: 2px solid var(--primary);
  padding: 0.5rem 1rem;
  position: relative;
  border-radius: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  width: 200px;
}

.acad-register-form .acad-register-btn:hover,
.login-form .primary-login-btn:focus {
  color: var(--primary);
  background-color: white;
}

@media screen and (min-device-width: 1200px) and (max-device-width: 2100px) and (-webkit-min-device-pixel-ratio: 1) {
  .register-row {
    margin: 0 0 50px 0;
  }
}

.reg-btns {
  margin-bottom: 0.35rem;
  align-self: center;
}

.arrow-icon {
  position: absolute;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
}

.arrow {
  background: var(--primary);
  width: 50px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    background: var(--primary);
    position: absolute;
    height: 3px;
    width: 15px;
  }

  &:before {
    right: -3px;
    bottom: -6px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -3px;
    top: -6px;
    transform: rotate(45deg);
  }
}

@media (max-width: 1300px) {
  .acad-register-container {
    margin-top: 170px;
  }

  .acad-register-row {
    flex-direction: column;
    gap: 0.75rem;
  }

  .acad-register-row h1 {
    font-size: 1.75rem;
    margin-bottom: 0;
    min-width: none;
  }

  .back-to-reg-btn {
    position: absolute;
    top: -100px;
  }
}

@media (max-width: 1150px) {
  .register-container {
    flex-direction: column;
  }

  .register-title {
    margin: 0;
    font-size: 1.75rem;
  }

  .register-title-row {
    margin: 0;
  }

  .register-row {
    margin-left: -100px;
  }
}
