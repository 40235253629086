.add-question {
  flex: 4 1;
  padding: 20px;
}

.addQuestionTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addQuestionTitle {
  flex: 1;
  font-size: 40px;
}

.addQuestionInfoBottom {
  margin-top: 10px;
}

.addQuestionBottom {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.addQuestionForm {
  display: block;
  margin-top: 20px;
}

.addQuestionFormLeft,
.addQuestionFormRight {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px;
}

.addQuestionFormLeft > label,
.addQuestionFormRight > label {
  margin-bottom: 10px;
  color: gray;
  font-weight: 600;
}

.addQuestionFormLeft > input,
.addQuestionFormRight > input,
.addQuestionFormLeft > textarea {
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-bottom: 1px solid gray;
}

.addQuestionFormLeft > select,
.addQuestionFormRight > select {
  margin-bottom: 10px;
}

.addQuestionButton {
  width: 100%;
  margin: 30px;
  border: none;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
