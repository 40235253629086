.dashboard {
    margin-top: 35px;
    margin-bottom: 30px;
    width: 50%;
    padding: 10px;
    margin-left: 150px;
}

/* New Title Style */
.dashboard-title {
    top: 5px;
    font-size: 34px;
    color: white;
    margin-bottom: 20px;
    font-weight: bold;
}

/* Updated Points Section */
.points-section {
    background-color: #2d2d2d;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.points-section h2 {
    font-size: 20px;
    color: white;
    font-weight: bold;
}

.points-section h2 span {
    color: #5be14b;
    font-size: 21px;
}

/* Redeem Points Button */
.redeem-button {
    background-color: #FF9933;
    color: white;
    border: none;
    margin-right: 39px;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

.redeem-button:hover {
    background-color: #F2994A;
}

/* New Point History Section */
.point-history-section {
    background-color: #2d2d2d;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    color: white;
}

.point-history-section h3 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: bold;
}

.point-history-section ol {
    list-style-position: inside;
    padding-left: 0;  /* Remove padding for a consistent start */
    margin: 0;  /* Remove default margin */
}

.point-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #444;
}

.point-entry:last-child {
    border-bottom: none;
}

.point-entry .activity {
    font-weight: bold;
    flex: 1;  /* Allow activity to take up remaining space */
}

.point-entry .date {
    color: #ccc;
    text-align: left;  /* Align date to the right */
    margin-right: 10px;
    flex: 0 0 auto;  /* Prevents date from growing or shrinking */
    width: 120px; /* Ensures a consistent width for dates */
    padding-right: 406px; /* Adds spacing between date and points */
}


.point-entry .points-positive {
    color: #5be14b;
    font-weight: bold;
}

.point-entry .points-negative {
    color: #ff0000;
    font-weight: bold;
}

/* New Earn Badges Section */
.earn-badges-section {
    background-color: #2d2d2d;
    padding: 10px;
    border-radius: 8px;
    height: 693px;
}

.earn-badges-section h3 {
    font-size: 20px;
    color: white;
    font-weight: bold;
    padding-bottom: 15px;
}



/* Progress bar styles */
.progress-bar {
    margin-bottom: 20px;
}

.progress-bar label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
}

.progress-bar progress {
    width: 100%;
    height: 14px;
    appearance: none;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
}

progress::-webkit-progress-bar {
    background-color: #ddd;
    border-radius: 5px;
}

progress::-webkit-progress-value {
    background-color: #FF9933;
    border-radius: 5px;
}

progress::-moz-progress-bar {
    background-color: #FF9933;
}

.progress-bar span {
    display: block;
    margin-top: 5px;
    color: #ccc;
    font-size: 14px;
}
