.about-container {
  text-align: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding: 30px;
  margin-bottom: 20px;
  margin-top: 100px;
}

.about-info-row {
  padding: 0 30px 50px 30px;
  margin-right: 0 !important;
  margin-left: 0 !important;
  justify-content: center;
  display: flex;
}

.our-team-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  justify-content: center;
  display: flex;
}

.knowquest-intro {
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
  color: #384158;
  line-height: 1.2;
}

.card-vision-intro {
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
  color: #384158;
  line-height: 1.2;
}

.team-title {
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
  color: #384158;
  line-height: 1.2;
}

.sub-text-intro {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.85;
  font-weight: 450;
  color: #76777a;
  text-shadow: rgb(0 0 0 / 1%) 0 0 1px;
}

.team-img-class {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  z-index: 5;
}

/* .card-img-top{
    
} */
.card-img-top-team {
  height: 160px;
  width: 180px;
  border-radius: 10px 10px 0 0px;
  margin-bottom: -86px;
}

.team-row {
  width: 80%;
  margin: auto;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.aboutcard {
  border: none;
  padding: 20px;
}

.linkedIn-logo {
  font-size: 25px;
  color: #76777a;
}

.linkedIn-logo:hover {
  color: #ff9933;
  transition: all 0.1s ease 0s;
  opacity: 1;
}

.card-vision-intro:hover {
  border: none;
  color: #ff9933;
  transition: all 0.1s ease 0s;
  opacity: 1;
}

.team-text-role {
  font-size: 20px;
  font-weight: 400;
  color: #76777a;
  margin-top: 6px;
}

.team-col {
  margin-top: 10px;
}

.team-devloper-col {
  margin-top: 10px;
}

.main-card-about {
  display: flex;
  justify-content: center;
}

.cards-about-data-info {
  margin: auto;
  display: flex;
  justify-content: center;
}

.team-member-body:hover {
  /* margin: 10px; */
  border: none;
  /* padding: 10px; */
  box-shadow: 0 2px 8px rgb(0 0 0 / 30%);
  transition: all 0.3s ease 0s;
  /* transform: scale(1.1, 1.1); */
  opacity: 1;
}

.about-team-member-card {
  border: none;
  /* box-shadow: 0 2px 8px rgb(0 0 0 / 10%); */
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1); */
  margin: 10px;
  /* padding: 20px; */
}

.team-member-body {
  padding-top: 108px;
  border: none;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
}

.team-developer-body {
  padding-top: 50px;
  border: none;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
}

.team-developer-body:hover {
  /* margin: 10px; */
  border: none;
  /* padding: 10px; */
  box-shadow: 0 2px 8px rgb(0 0 0 / 30%);
  transition: all 0.3s ease 0s;
  /* transform: scale(1.1, 1.1); */
  opacity: 1;
}
