.login {
  position: relative;
  color: var(--primary);
  margin-top: 130px;
  font-family: 'Montserrat';
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4rem;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.login .title {
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
}

.login .title h2 {
  font-weight: 800;
  font-size: 3rem;
}

.login-img {
  object-fit: contain;
  height: 500px;
  width: 100%;
}

.login-form {
  font-weight: 500;
}

.login-form form {
  position: relative;
}
/* 
.login-form input {
  background: none;
  border-radius: 0;
  width: 400px;
  border: none;
  border-bottom: 2px solid var(--primary);
} */

/* .login-form input::placeholder {
  color: #999;
  margin-left: -10px;
  padding-left: 0;
} */

.login-form input:focus {
  background: none;
  outline: none !important;
  box-shadow: none;
  border-color: var(--secondary);
}

.login-form .primary-login-btn {
  background-color: var(--primary);
  border: 2px solid var(--primary);
  padding: 0.5rem 1rem;
  position: relative;
  border-radius: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
}

.login-form .primary-login-btn:hover,
.login-form .primary-login-btn:focus {
  color: var(--primary);
  background-color: white;
}

.login-form a {
  color: var(--secondary);
}

.login-form .no-account {
  text-align: center;
}

.login-btn {
  padding: 5px 5px 5px 0;
  background: none;
  display: flex;
  justify-content: center;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

.hr-text:before {
  content: '';
  background: linear-gradient(to right, transparent, white, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;

  padding: 0 0.5em;
  line-height: 1.5em;
  color: white;
  background-color: var(--primary);
}

@media (max-width: 1000px) {
  .login {
    gap: 1rem;
  }
}

@media (max-width: 900px) {
  .login {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .login .title {
    text-align: center;
    display: flex;
    justify-content: center;
    height: 20px;
  }

  .login .title h2 {
    font-size: 1.5rem;
    margin: 0;
  }
}
