.partner_text {
  text-align: center;
}

.img {
  max-width: 100%;
  height: auto;
  width: 150px;
  height: auto;
}

.partner_div {
  border: 3px solid black;
  text-align: center;
  width: 300px;
  height: 450px;
  background: #ffffff;
  box-shadow: 0px 1px 30px rgba(29, 34, 47, 0.2);
  padding-top: 22px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 23px;
  margin-left: 50px;
  margin-right: 50px;
}

.a {
  color: black;
  transition: all 0.2s linear;
}

.a:hover {
  color: black;
}

.one h1 {
  font-weight: 500;
  font-family: Georgia, serif;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.one h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: '';
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #110f2d;
}

.one h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: '';
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: #110f2d;
}

.container1 {
  text-align: center;
  margin-right: 20px;
  width: 30%;
  padding: 20px;
  margin-top: 50px;
}

.float-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  background-color: #b8c6db;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
  padding: 20px;
}

.hr {
  width: 150px;
  border: 1px solid #110f2d;
}

.logo_image img {
  max-width: 100%;
}

.grid-container {
  place-items: center;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
}

.grid-item {
  text-align: center;
  padding: 20px;
}

.contributor_title {
  font-weight: 500;
  font-family: Georgia, serif;
  text-align: center;
}

.uni_image {
  width: 100px;
}

.uni_name {
  font-size: small;
}

.uni_container {
  text-align: center;
}

.info_div {
  margin-left: 50px;
  margin-right: 50px;
}
