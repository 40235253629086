.edit-category {
  flex: 4;
  padding: 20px;
}

.categoryTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categoryAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.categoryTop {
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.categoryInfoImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.categoryInfoTop {
  display: flex;
  align-items: center;
}

.editCategoryTitle {
  font-size: 40px;
}

.categoryTitle {
  font-weight: 600;
}

.categoryInfoBottom {
  margin-top: 10px;
}

.categoryInfoItem {
  width: 200px;
  display: flex;
}

.categoryInfoValue {
  font-weight: 300;
  margin-left: 10px;
}

.categoryBottom {
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.categoryForm {
  margin-top: 20px;
}

.categoryFormLeft {
  display: flex;
  flex-direction: column;
}

.categoryFormLeft > label {
  margin-bottom: 10px;
  color: gray;
}

.categoryFormLeft > input {
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-bottom: 1px solid gray;
}

.categoryFormLeft > select {
  margin-bottom: 10px;
}

.categoryUploadImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.categoryFormRight {
  width: 200px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.categoryUpload {
  display: flex;
  align-items: center;
}

.categoryButton {
  border: none;
  padding: 5px;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
