.logo {
  aspect-ratio: 1/1;
  width: 4rem;
}

.filter-button {
  background: none;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Open Sans';
  padding: 0;
  position: relative;
}

.filter-dropdown > .filter-button:focus,
.filter-button:hover {
  outline: none;
  color: #ff9933;
}

.filter-dropdown {
  position: relative;
}

.filter-dropdown-menu {
  position: absolute;
  opacity: 0;
  top: calc(100% + 0.5rem);
  border-radius: 0.25rem;
  padding: 0.75rem;
  box-shadow: 0 4px 4px -4px rgb(0, 0, 0, 0.2);
  transform: translateY(-10px) translateX(-80px);
  transition:
    opacity 300ms ease-in-out,
    transform 300ms ease-in-out;
  pointer-events: none;
  background-color: white;
  z-index: 9999;
}

.filter-dropdown:hover .filter-dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}

.switch-container {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 1rem;
  place-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 1rem;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
}

.slider:before {
  position: absolute;
  content: '';
  height: 0.6rem;
  width: 0.6rem;
  left: 1px;
  bottom: 3px;
  background-color: white;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.searchbar-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.searchbar-icon {
  display: none;
}

.header-searchbar {
  border-radius: 100vw;
  width: 38rem;
  max-width: 100%;
  height: 40px;
  font-size: 15px;
  border: 1px solid #444444;
  padding-left: 10px;
  /* margin-left: 1rem; */
}

.searchbar-dropdown {
  z-index: 9999;
  position: absolute;
  width: 38rem;
  max-height: 20rem;
  background-color: white;
  border: 1px solid #444444;
  visibility: hidden;
  overflow-y: scroll;
  top: calc(100% + 0.25rem);
  border-radius: 0.5rem;
}

.header-searchbar:focus {
  outline: none;
}

.searchbar-item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  color: #444444;
  font-family: 'Open Sans';
  font-size: 0.8rem;
  padding: 0.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  align-items: center;
  gap: 4rem;
  justify-content: space-between;
  padding: 1rem;
  flex: auto;
}

.searchbar-url-item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  color: #444444;
  font-family: 'Open Sans';
  padding: 0.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  align-items: center;
  gap: 4rem;
  justify-content: space-between;
  padding: 1rem;
  flex: auto;
}

.first-searchbar-item {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  color: #444444;
  font-family: 'Open Sans';
  font-size: 0.95rem;
  padding: 0.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.search-image-and-title {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.first-searchbar-item .searchbar-image {
  aspect-ratio: 16/9;
  width: 11.5rem;
  border: 1px solid #444444;
  background: 'url(../../assets/images/noimagefound.png)';
}

.first-searchbar-item .search-image-and-title {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.searchbar-image {
  aspect-ratio: 16/9;
  width: 9rem;
  border-radius: 0.5rem;
  border: 1px solid #444444;
  background: 'url(../../assets/images/noimagefound.png)';
}

.nav-links {
  text-decoration: none !important;
  color: #000000;
}

.fa-user {
  color: #333399;
}

.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Make it circular */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}

.user-icon-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

/* .dropdown-toggle::after{
  color: #333399;
} */
.dropdown-toggle1::after {
  color: white;
}

#navbarDropdown {
  color: #333399;
}

#navbarDropdown1 {
  color: #333399;
  font-weight: bold;
  font-family: 'Hind';
}

.nav-link:hover {
  color: white;
  text-decoration: none !important;
}

.main-nav {
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0;
}

.main-nav {
  background-color: white;
}

.username {
  font-weight: 600;
  color: #333399;
}

.sub-nav {
  width: 100%;
  align-items: center;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  padding: 10px;
}

.sub-dropdown {
  position: relative;
}

.sub-nav-items {
  display: flex;
  list-style: none;
  gap: 100px;
  justify-content: center;
  padding-bottom: 0;
  background-color: white;
  border: none;
}

.sub-nav-items-text {
  color: #444444;
  font-family: 'Montserrat';
  font-weight: 600;
}

.sub-nav-items-text:hover {
  color: #ff9933;
}

.sub-dropdown-menu {
  opacity: 0;
  position: absolute;
  top: 100%;
  width: fit-content;
  border-radius: 0.25rem;
  padding: 0.75rem;
  box-shadow: 0 4px 4px -4px rgb(0, 0, 0, 0.2);
  transform: translateY(-10px) translateX(-0rem);
  transition:
    opacity 300ms ease-in-out,
    transform 300ms ease-in-out;
  pointer-events: none;
  background-color: white;

  &.far-left {
    left: -0.8rem;
  }

  &.left {
    left: -0.2rem;
  }
}

.sub-dropdown:hover .sub-dropdown-menu {
  opacity: 1;
  transform: translateY(0) translateX(-0rem);
  pointer-events: auto;
}

.sub-dropdown-menu-item-grid {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 2rem;
}

.sub-dropdown-menu-item {
  overflow: hidden;
  white-space: nowrap;
}

.sub-dropdown-menu-nav-link {
  color: #444444;
  font-family: 'Hind';
  font-size: 1rem;
}

.sub-dropdown-menu-nav-link:hover {
  color: #ff9933;
}

.hamburger-menu {
  display: none;
}

.dropdown-menu {
  left: unset;
  min-width: 0;
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .navbar {
    /* width: 80%; */
    /* background-color: #fff !important; */
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .navbar {
    width: 100%;
    box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 900px) {
  .navbar {
    width: 100%;
    justify-content: center;
  }

  .logo {
    margin-left: 1.5rem;
  }

  .filter-button {
    display: none;
  }

  .header-searchbar {
    display: none;
    visibility: hidden;
  }

  .logo {
    display: none;
  }

  .user-icon-section {
    display: none;
  }

  .searchbar-icon {
    display: inline-block;
    position: relative;
  }

  .input-search {
    height: 3rem;
    width: 3rem;
    border: 1px solid black;
    border-radius: 100vw;
    padding: 10px;
    font-size: 1rem;
    outline: none;
    transition: width 0.5s ease-in-out;
    background-color: white;
    padding-right: 40px;
    color: black;
  }

  .btn-search {
    width: 3.1rem;
    height: 3.1rem;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0;
    color: var(--primary);
    background-color: transparent;
    pointer-events: painted;
  }

  .btn-search:focus {
    outline: none;
  }

  .input-search::placeholder {
    color: var(--darkgrey);
    font-size: 14px;
    font-weight: 200;
    font-family: 'Hind';
  }

  .btn-search:focus ~ .input-search {
    width: 30rem;
    border-radius: 100vw;
    background-color: transparent;
    transition: width 500ms;
  }

  .input-search:focus {
    width: 30rem;
    border-radius: 100vw;
    background-color: transparent;
    transition: width 500ms;
    outline: none;
  }

  .searchbar-container {
    gap: 0;
  }

  .searchbar-dropdown {
    width: 30rem;
    left: 0;
  }

  .first-searchbar-item .searchbar-image {
    width: 9rem;
  }

  .searchbar-image {
    width: 7rem;
  }

  .searchbar-item {
    font-size: 0.7rem;
  }

  .sub-nav {
    display: none;
  }

  .hamburger-menu {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    background-color: var(--primary);
    align-items: flex-start;
    width: 0;
    transition: width 0.2s;
    z-index: 9999;
    height: 100%;
  }

  .hamburger-menu.active {
    width: 40%;
  }

  .hamburger {
    font-size: 1.8rem;
    background-color: transparent;
    border: none;
    padding-top: 1rem;
  }

  .hamburger.active {
    border: none;
    background-color: var(--primary);
  }

  .hamburger:focus {
    outline: none;
  }

  .menu-item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    width: 100%;
    padding-left: 10px;
  }

  .menu-text {
    opacity: 0;
  }

  .menu-text.active {
    opacity: 1;
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 1);
    font-family: 'Hind', 'san-serif';
  }

  .navigation-link {
    color: white;
    text-decoration: none;
    width: fit-content;
  }

  .navigation-link:active {
    color: var(--secondary);
  }

  .login-registration-container {
    position: absolute;
    display: flex;
    justify-content: space-around;
    width: 100%;
    bottom: 0;
  }
}

@media only screen and (max-width: 750px) {
  .searchbar-dropdown {
    width: 25rem;
  }

  .input-search:focus {
    width: 25rem;
    transition: width 500ms;
  }

  .btn-search:focus ~ .input-search {
    width: 25rem;
  }
}

@media only screen and (max-width: 620px) {
  .searchbar-dropdown {
    width: 20rem;
  }

  .input-search:focus {
    width: 20rem;
    transition: width 500ms;
  }

  .btn-search:focus ~ .input-search {
    width: 20rem;
  }
}

@media only screen and (max-width: 480px) {
  .hamburger-menu.active {
    width: 100%;
  }

  .searchbar-dropdown {
    width: 10rem;
  }

  .input-search:focus {
    width: 10rem;
    transition: width 500ms;
  }

  .btn-search:focus ~ .input-search {
    width: 10rem;
  }

  .menu-item-container {
    align-items: center;
  }

  .first-searchbar-item {
    font-size: 0.7rem;
  }

  .first-searchbar-item .searchbar-image {
    width: 7rem;
  }

  .searchbar-image {
    width: 5rem;
  }

  .searchbar-item {
    font-size: 0.7rem;
  }
}
