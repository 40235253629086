.question-main-container {
  flex: 6;
}

.questionList {
  height: calc(100vh - 150px);
}

.question-heading {
  margin: 0 !important;
}

#dropdown-menu {
  height: 40px;
  margin: 10px;
}

.question-title,
.questionAddButton {
  margin: 10px;
}

.questionAddButton {
  width: 150px !important;
}
