.movieList {
  flex: 4;
  margin: 0;
}

.movieListItem {
  /* display: flex; */
  /* height: 100%; */
  /* flex-wrap: wrap; */
  align-items: center;
  margin: 10px !important;
}

.movieListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.movieListEdit {
  color: green;
  margin-right: 20px;
  margin-top: 20px;
}

.movieListDelete {
  color: red;
  cursor: pointer;
}

.movieAddButton {
  width: 140px !important;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.ratingTitleContainer {
  margin: 0 0 10px 0;
  padding: 10px;
}

.react-bootstrap-table-pagination-list,
.react-bootstrap-table-pagination {
  margin: 0 !important;
}

.react-bootstrap-table-pagination-list {
  text-align: right;
  margin-left: auto;
}

.rating-title {
  padding-left: 10px;
  text-align: left;
  margin: 0;
  padding: 0;
}
