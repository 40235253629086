@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Hind', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --faded: #d3dce7;
  --primary: hsla(224, 58%, 13%, 1);
  --secondary: hsl(30, 100%, 60%);
  --primary-hsl: 240, 50%, 40%;
  --secondary-hsl: 30, 100%, 60%;
}

input,
textarea,
button,
select {
  font: inherit;
}

svg {
  display: inline-block;
}
