.contest_info {
  text-align: center;
  margin-top: 100px;
}

.contest {
  margin-left: 30%;
  margin-right: 30%;
  text-align: center;
  align-items: center;
}

.container1 {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #b8c6db;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
  margin-top: 7rem;
}

.hh {
  width: 50%;
  border: 1px solid #110f2d;
}
