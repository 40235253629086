.edit-question {
  flex: 4;
  padding: 20px;
}

.questionTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.questionAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.questionTop {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.questionInfoImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.questionInfoTop {
  display: flex;
  align-items: center;
}

.editQuestionTitle {
  font-size: 40px;
}

.questionName {
  font-weight: 600;
}

.questionInfoBottom {
  margin-top: 10px;
}

.questionInfoItem {
  width: 200px;
  display: flex;
}

.questionInfoValue {
  font-weight: 300;
  margin-left: 10px;
}

.questionBottom {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.questionForm {
  margin-top: 20px;
}

.questionFormLeft {
  display: flex;
  flex-direction: column;
}

.questionFormLeft > label {
  margin-bottom: 10px;
  color: gray;
}

.questionFormLeft > input {
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-bottom: 1px solid gray;
}

.questionFormLeft > select {
  margin-bottom: 10px;
}

.questionUploadImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.questionFormRight {
  width: 200px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.questionUpload {
  display: flex;
  align-items: center;
}

.questionButton {
  border: none;
  padding: 5px;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
