.signup {
  text-align: center;
  margin-top: 50px;
}

.signup form {
  display: inline-block;
  text-align: left;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.signup h1 {
  color: white;
  font-size: 30px;
  font-family: sans-serif;
  font-weight: bold;
}

.signup div {
  margin-bottom: 25px;
}

.signup label {
  display: inline-block;
  width: 500px;
  margin-bottom: 5px;
}

.signup input,
.signup select {
  padding: 10px;
  width: 500px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5;
  appearance: none;
  background-clip: padding-box;
}

.signup input[type="file"] {
  color: white;
}

.signup input[type="file"]::file-selector-button {
  background-color: #FF9933;
}


.signup select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpolygon fill='%23666' points='1,4 11,4 6,9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px;
  width: 500px;
}

.signup select[multiple] {
  height: auto;
  min-height: 100px;
}

.signup button {
  background-color: #ff9933;
  color: white;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: auto;
  width: 200px;
}

.signup button:hover {
  background-color: #ff9933;
}

.signup .error-message {
  color: #ff4444;
  font-size: 0.8rem;
  margin-top: 4px;
  display: block;
}

.signup button:disabled {
  background-color: #666;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.signup input[type="password"] {
  margin-bottom: 4px;
}

