.reviews-section {
    width: 25%;
    background-color: #2d2d2d;
    padding: 20px;
    color: white;
    position: absolute;
    top: 247px;
    right: 175px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    height: 640px;
    margin-bottom: 20px;
}

.reviews-section h3{
    font-size: 20px;
    color: white;
    font-weight: bold;
}

.review {
    border: 1px solid #444;
    background-color: #3a3a3a;
    margin-bottom: 10px;
    padding: 30px;
    margin-top: 20px;
    border-radius: 5px;
}

.refer-friend-section {
    width: 25%;
    height: 230px;
    background-color: #2d2d2d;
    padding: 20px;
    color: white;
    position: absolute;
    top: 931px;
    right: 175px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.refer-friend-section h3 {
    font-size: 20px;
    color: white;
    font-weight: bold;
    margin-bottom: 12px;
}

.referral-link {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #444;
    border: none;
    color: white;
    border-radius: 5px;
}

.refer-friend-section button {
    background-color: #FF9933;;
    color: white;
    font-weight: bold;
    font-size: medium;
    padding: 10px;
    border: none;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
}

.refer-friend-section button:hover {
    background-color: #F2994A;
}
