.reward {
  text-align: center;
  margin-top: 50px;
}

.reward form {
display: inline-block;
text-align: left;
padding: 30px;
border: 1px solid #ccc;
border-radius: 8px;
}

.reward h1 {
font-size: 30px;
color: white;
font-family: sans-serif;
font-weight: bold;
}

.reward div {
margin-bottom: 25px;
}

.reward label {
display: inline-block;
width: 500px;
margin-bottom: 5px;
}

.reward input {
padding: 5px;
width: 500px;
}

.reward input[type="file"] {
color: white;
}

.reward input[type="file"]::file-selector-button {
background-color: #FF9933;
}

.reward textarea[name=rewardDescription] {
padding: 5px;
width: 500px;
border: 1px solid #ccc;  
}

.reward textarea[name=rewardTerms] {
padding: 5px;
width: 500px;
height: 200px;
border: 1px solid #ccc;
}

.reward button {
background-color: #ff9933;
color: white;
padding: 10px 20px;
border: 1px solid #ccc;
border-radius: 4px;
cursor: pointer;
display: block;
margin: auto;
width: 200px;
}

.reward button:hover {
background-color: #ff9933;
}
